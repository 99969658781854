import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { CheckboxCell } from '../../../../shared/Components/GenericComponent';
import { StatusTag } from '../../../../shared/Components/StatusTag';
import { convertToMt, toLocalDateTime } from '../../../../shared/Utils/Helper';
import ColumnFilters from './ColumnFilters';

function TooltipHover({ component, value }) {
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {component}
    </Tooltip>
  );

  return (
    <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={renderTooltip}>
      <span className="tooltip-data" style={{ cursor: 'pointer' }}>
        {value}
      </span>
    </OverlayTrigger>
  );
}

export const BATCH_COLUMN_PRO = [
  {
    Header: 'Batch ID',
    accessor: 'batchId',
    Cell: (cellInfo) => {
      const status = cellInfo?.data?.[cellInfo?.cell?.row?.index]?.status;
      const condition = status === 'REJECTED' || status === 'DELETED';
      return (
        <span
          id={cellInfo?.row?.id}
          className={!condition ? 'text-primary' : ''}
          style={{ color: condition ? 'gray' : '', cursor: condition ? 'none' : 'pointer' }}>
          {cellInfo?.cell?.value}
        </span>
      );
    },
    isSticky: true,
    disableSortBy: true,
    Filter: ColumnFilters,
  },
  {
    Header: 'Batch Creation Date',
    accessor: 'uploadedAt',
    Cell: (cellInfo) => {
      return <span>{toLocalDateTime(cellInfo?.cell?.value)}</span>;
    },
    disableSortBy: true,
  },
  {
    Header: 'Username',
    accessor: 'username',
    Cell: (cellInfo) => (
      <TooltipHover
        value={
          cellInfo?.cell?.value
            ? cellInfo?.cell?.value?.length >= 25
              ? cellInfo?.cell?.value.slice(0, 25) + '...'
              : cellInfo?.cell?.value
            : '-'
        }
        component={<span>{cellInfo?.cell?.value}</span>}
      />
    ),
    disableSortBy: true,
    isTooltipColumn: true,
  },
  {
    Header: 'Service Provider',
    accessor: 'serviceProviderName',
    Cell: (cellInfo) => {
      return (
        <TooltipHover
          value={
            cellInfo?.cell?.value
              ? cellInfo?.cell?.value?.length >= 25
                ? cellInfo?.cell?.value.slice(0, 25) + '...'
                : cellInfo?.cell?.value
              : '-'
          }
          component={<span>{cellInfo?.cell?.value}</span>}
        />
      );
    },
    disableSortBy: true,
    Filter: ColumnFilters,
    isTooltipColumn: true,
  },
  {
    Header: 'Recycler',
    accessor: 'recyclerName',
    Cell: (cellInfo) => {
      return (
        <TooltipHover
          value={
            cellInfo?.cell?.value
              ? cellInfo?.cell?.value?.length >= 25
                ? cellInfo?.cell?.value.slice(0, 25) + '...'
                : cellInfo?.cell?.value
              : '-'
          }
          component={<span>{cellInfo?.cell?.value}</span>}
        />
      );
    },
    disableSortBy: true,
    Filter: ColumnFilters,
    isTooltipColumn: true,
  },
  {
    Header: 'ZIP File name',
    accessor: 'originalFileName',
    Cell: (cellInfo) => {
      return (
        <TooltipHover
          value={
            cellInfo?.cell?.value
              ? cellInfo?.cell?.value?.replace('.zip', '')?.length >= 25
                ? cellInfo?.cell?.value?.replace('.zip', '').slice(0, 25) + '...'
                : cellInfo?.cell?.value?.replace('.zip', '')
              : '-'
          }
          component={<span>{cellInfo?.cell?.value?.replace('.zip', '')}</span>}
        />
      );
    },
    disableSortBy: true,
    isTooltipColumn: true,
  },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: (cellInfo) => {
      return <StatusTag status={cellInfo?.cell?.value} />;
    },
    disableSortBy: true,
  },
];

export const BATCH_COLUMN = [
  {
    Header: 'Batch ID',
    accessor: 'batchId',
    Cell: (cellInfo) => {
      const status = cellInfo?.data?.[cellInfo?.cell?.row?.index]?.status;
      const condition = status === 'REJECTED' || status === 'DELETED';
      return (
        <span
          id={cellInfo?.row?.id}
          className={!condition ? 'text-primary' : ''}
          style={{ color: condition ? 'gray' : '', cursor: condition ? 'none' : 'pointer' }}>
          {cellInfo?.cell?.value}
        </span>
      );
    },
    isSticky: true,
    disableSortBy: true,
    Filter: ColumnFilters,
  },
  {
    Header: 'Batch Creation Date',
    accessor: 'uploadedAt',
    Cell: (cellInfo) => {
      return <span>{toLocalDateTime(cellInfo?.cell?.value)}</span>;
    },
    disableSortBy: true,
  },
  {
    Header: 'ZIP File Name',
    accessor: 'originalFileName',
    Cell: (cellInfo) => {
      return (
        <TooltipHover
          value={
            cellInfo?.cell?.value
              ? cellInfo?.cell?.value?.replace('.zip', '')?.length >= 25
                ? cellInfo?.cell?.value?.replace('.zip', '').slice(0, 25) + '...'
                : cellInfo?.cell?.value?.replace('.zip', '')
              : '-'
          }
          component={<span>{cellInfo?.cell?.value?.replace('.zip', '')}</span>}
        />
      );
    },
    disableSortBy: true,
    isTooltipColumn: true,
  },
  {
    Header: 'Processing Status',
    accessor: 'status',
    Cell: (cellInfo) => {
      return <StatusTag status={cellInfo?.cell?.value} />;
    },
    disableSortBy: true,
  },
];

export const PROCESSED_BATCH_COLUMN_PRO = [
  {
    Header: 'Batch ID',
    accessor: 'batchId',
    Cell: (cellInfo) => {
      const status = cellInfo?.data?.[cellInfo?.cell?.row?.index]?.status;
      const condition = status === 'REJECTED' || status === 'DELETED';
      return (
        <Link
          style={{
            pointerEvents: condition ? 'none' : '',
            color: condition ? 'gray' : '',
          }}
          to={`bulk-delivery/${cellInfo?.cell?.value}`}>
          {cellInfo?.cell?.value}
        </Link>
      );
    },
    isSticky: true,
    disableSortBy: true,
    Filter: ColumnFilters,
    disableFilter: false,
  },
  {
    Header: 'Batch Creation Date',
    accessor: 'uploadedAt',
    Cell: (cellInfo) => {
      return <span>{toLocalDateTime(cellInfo?.cell?.value)}</span>;
    },
    disableSortBy: true,
    disableFilter: false,
  },
  {
    Header: 'Username',
    accessor: 'username',
    Cell: (cellInfo) => (
      <TooltipHover
        value={
          cellInfo?.cell?.value
            ? cellInfo?.cell?.value?.length >= 25
              ? cellInfo?.cell?.value.slice(0, 25) + '...'
              : cellInfo?.cell?.value
            : '-'
        }
        component={<span>{cellInfo?.cell?.value}</span>}
      />
    ),
    disableSortBy: true,
    isTooltipColumn: true,
  },
  {
    Header: 'Service Provider',
    accessor: 'serviceProviderName',
    Cell: (cellInfo) => {
      return (
        <TooltipHover
          value={
            cellInfo?.cell?.value
              ? cellInfo?.cell?.value?.length >= 25
                ? cellInfo?.cell?.value.slice(0, 25) + '...'
                : cellInfo?.cell?.value
              : '-'
          }
          component={<span>{cellInfo?.cell?.value}</span>}
        />
      );
    },
    disableSortBy: true,
    Filter: ColumnFilters,
    isTooltipColumn: true,
  },
  {
    Header: 'Recycler',
    accessor: 'recyclerName',
    Cell: (cellInfo) => {
      return (
        <TooltipHover
          value={
            cellInfo?.cell?.value
              ? cellInfo?.cell?.value?.length >= 25
                ? cellInfo?.cell?.value.slice(0, 25) + '...'
                : cellInfo?.cell?.value
              : '-'
          }
          component={<span>{cellInfo?.cell?.value}</span>}
        />
      );
    },
    disableSortBy: true,
    Filter: ColumnFilters,
    isTooltipColumn: true,
  },
  {
    Header: 'ZIP File name',
    accessor: 'originalFileName',
    Cell: (cellInfo) => {
      return (
        <TooltipHover
          value={
            cellInfo?.cell?.value
              ? cellInfo?.cell?.value?.replace('.zip', '')?.length >= 25
                ? cellInfo?.cell?.value?.replace('.zip', '').slice(0, 25) + '...'
                : cellInfo?.cell?.value?.replace('.zip', '')
              : '-'
          }
          component={<span>{cellInfo?.cell?.value?.replace('.zip', '')}</span>}
        />
      );
    },
    disableSortBy: true,
    isTooltipColumn: true,
  },
  {
    Header: 'Invalid documents',
    accessor: 'invalidDocsCount',
    Cell: (cellInfo) => {
      return <span className="text-danger">{cellInfo?.cell?.value ?? '-'}</span>;
    },
    disableSortBy: true,
  },

  {
    Header: 'Draft',
    accessor: 'totalTransactions',
    Cell: (cellInfo) => {
      return <span>{cellInfo?.cell?.value ?? '-'}</span>;
    },
  },
  {
    Header: 'Allocated',
    accessor: 'totalDeliveries',
    Cell: (cellInfo) => {
      return <span>{cellInfo?.cell?.value ?? '-'}</span>;
    },
  },
  {
    Header: 'To Be Allocated',
    accessor: 'readyToAllocateCount',
    Cell: (cellInfo) => {
      return <span>{cellInfo?.cell?.value ?? ''}</span>;
    },
    isSticky: true,
  },
];

export const PROCESSED_BATCH_COLUMN = [
  {
    Header: 'Batch ID',
    accessor: 'batchId',
    Cell: (cellInfo) => {
      const status = cellInfo?.data?.[cellInfo?.cell?.row?.index]?.status;
      const condition = status === 'REJECTED' || status === 'DELETED';
      return (
        <Link
          style={{
            pointerEvents: condition ? 'none' : '',
            color: condition ? 'gray' : '',
          }}
          to={`bulk-delivery/${cellInfo?.cell?.value}`}>
          {cellInfo?.cell?.value}
        </Link>
      );
    },
    isSticky: true,
    disableSortBy: true,
    Filter: ColumnFilters,
  },
  {
    Header: 'Batch Creation Date',
    accessor: 'uploadedAt',
    Cell: (cellInfo) => {
      return <span>{toLocalDateTime(cellInfo?.cell?.value)}</span>;
    },
    disableSortBy: true,
  },
  {
    Header: 'Username',
    accessor: 'username',
    Cell: (cellInfo) => {
      return (
        <TooltipHover
          value={
            cellInfo?.cell?.value
              ? cellInfo?.cell?.value?.length >= 25
                ? cellInfo?.cell?.value.slice(0, 25) + '...'
                : cellInfo?.cell?.value
              : '-'
          }
          component={<span>{cellInfo?.cell?.value}</span>}
        />
      );
    },
    disableSortBy: true,
    isTooltipColumn: true,
  },
  {
    Header: 'ZIP File name',
    accessor: 'originalFileName',
    Cell: (cellInfo) => {
      return (
        <TooltipHover
          value={
            cellInfo?.cell?.value
              ? cellInfo?.cell?.value?.replace('.zip', '')?.length >= 25
                ? cellInfo?.cell?.value?.replace('.zip', '').slice(0, 25) + '...'
                : cellInfo?.cell?.value?.replace('.zip', '')
              : '-'
          }
          component={<span>{cellInfo?.cell?.value?.replace('.zip', '')}</span>}
        />
      );
    },
    disableSortBy: true,
    isTooltipColumn: true,
  },
  {
    Header: "Total Draft DL's",
    accessor: 'totalTransactions',
    Cell: (cellInfo) => {
      return <span>{cellInfo?.cell?.value ?? '-'}</span>;
    },
  },
  {
    Header: "DL's Allocated",
    accessor: 'totalDeliveries',
    Cell: (cellInfo) => {
      return <span>{cellInfo?.cell?.value ?? '-'}</span>;
    },
    disableSortBy: true,
  },
  {
    Header: 'No. of Invalid documents',
    accessor: 'invalidDocsCount',
    Cell: (cellInfo) => {
      return <span className="text-danger">{cellInfo?.cell?.value ?? '-'}</span>;
    },
    disableSortBy: true,
  },
];

export const PENDING_BATCH_COLUMN = [
  {
    id: 'selection',
    Cell: ({ row }) => {
      const condition = row.original?.taggedFields === row.original?.totalTaggedFields;
      return (
        <div className="d-flex justify-content-center">
          <CheckboxCell
            getToggleSelectionProps={row.getToggleRowSelectedProps}
            disabled={condition}
          />
        </div>
      );
    },
    disableSortBy: true,
  },
  {
    Header: 'Draft DL #',
    accessor: 'referenceId',
    Cell: (cellInfo) => {
      return <span>{cellInfo?.cell?.value ?? '-'}</span>;
    },
    disableSortBy: true,
  },
  {
    Header: 'Vendor name',
    accessor: 'serviceProviderName',
    Cell: (cellInfo) => {
      return (
        <TooltipHover
          value={
            cellInfo?.cell?.value
              ? cellInfo?.cell?.value?.length >= 25
                ? cellInfo?.cell?.value.slice(0, 25) + '...'
                : cellInfo?.cell?.value
              : '-'
          }
          component={<span>{cellInfo?.cell?.value}</span>}
        />
      );
    },
    Filter: ColumnFilters,
    isTooltipColumn: true,
    disableSortBy: true,
  },
  {
    Header: 'No. of Mandatory fields tagged',
    accessor: 'taggedFields',
    Cell: ({ row }) => (
      <span>
        {row.original?.taggedFields ?? 0} / {row.original?.totalTaggedFields ?? 0}
      </span>
    ),
  },
  {
    Header: 'Qty',
    accessor: 'finalQuantity',
    Cell: ({ cell: { row } }) => {
      return <span>{`${convertToMt(row.original?.finalQuantity ?? 0, 2)} MT`}</span>;
    },
  },
];

export const ALLOCATED_BATCH_COLUMN_PRO = [
  {
    Header: 'Batch ID',
    accessor: 'batchId',
    Cell: (cellInfo) => {
      const status = cellInfo?.data?.[cellInfo?.cell?.row?.index]?.status;
      const condition = status === 'REJECTED' || status === 'DELETED';
      return (
        <Link
          style={{
            pointerEvents: condition ? 'none' : '',
            color: condition ? 'gray' : '',
          }}
          to={`bulk-delivery/${cellInfo?.cell?.value}?status=ALLOCATED`}>
          {cellInfo?.cell?.value}
        </Link>
      );
    },
    isSticky: true,
    disableSortBy: true,
    Filter: ColumnFilters,
  },
  {
    Header: 'Batch Creation date',
    accessor: 'uploadedAt',
    Cell: (cellInfo) => <span>{toLocalDateTime(cellInfo?.cell?.value) ?? '-'}</span>,
    disableSortBy: true,
  },
  {
    Header: 'Username',
    accessor: 'username',
    Cell: (cellInfo) => (
      <TooltipHover
        value={
          cellInfo?.cell?.value
            ? cellInfo?.cell?.value?.length >= 25
              ? cellInfo?.cell?.value.slice(0, 25) + '...'
              : cellInfo?.cell?.value
            : '-'
        }
        component={<span>{cellInfo?.cell?.value}</span>}
      />
    ),
    disableSortBy: true,
    isTooltipColumn: true,
  },
  {
    Header: 'Service Provider',
    accessor: 'serviceProviderName',
    Cell: (cellInfo) => {
      return (
        <TooltipHover
          value={
            cellInfo?.cell?.value
              ? cellInfo?.cell?.value?.length >= 25
                ? cellInfo?.cell?.value.slice(0, 25) + '...'
                : cellInfo?.cell?.value
              : '-'
          }
          component={<span>{cellInfo?.cell?.value}</span>}
        />
      );
    },
    Filter: ColumnFilters,
    isTooltipColumn: true,
    disableSortBy: true,
  },
  {
    Header: 'Recycler',
    accessor: 'recyclerName',
    Cell: (cellInfo) => {
      return (
        <TooltipHover
          value={
            cellInfo?.cell?.value
              ? cellInfo?.cell?.value?.length >= 25
                ? cellInfo?.cell?.value.slice(0, 25) + '...'
                : cellInfo?.cell?.value
              : '-'
          }
          component={<span>{cellInfo?.cell?.value}</span>}
        />
      );
    },
    Filter: ColumnFilters,
    isTooltipColumn: true,
    disableSortBy: true,
  },
  {
    Header: 'Zip file name',
    accessor: 'originalFileName',
    Cell: (cellInfo) => (
      <TooltipHover
        value={
          cellInfo?.cell?.value
            ? cellInfo?.cell?.value?.replace('.zip', '')?.length >= 25
              ? cellInfo?.cell?.value?.replace('.zip', '').slice(0, 25) + '...'
              : cellInfo?.cell?.value?.replace('.zip', '')
            : '-'
        }
        component={<span>{cellInfo?.cell?.value?.replace('.zip', '')}</span>}
      />
    ),
    disableSortBy: true,
    isTooltipColumn: true,
  },
  {
    id: 'recycler',
    Header: 'Vendor Name',
    accessor: 'serviceProviderName',
    Cell: (cellInfo) => {
      return (
        <TooltipHover
          value={
            cellInfo?.cell?.value
              ? cellInfo?.cell?.value?.length >= 25
                ? cellInfo?.cell?.value.slice(0, 25) + '...'
                : cellInfo?.cell?.value
              : '-'
          }
          component={<span>{cellInfo?.cell?.value}</span>}
        />
      );
    },
    disableSortBy: true,
    Filter: ColumnFilters,
    isTooltipColumn: true,
  },
  {
    Header: 'Workorder ID',
    accessor: 'workOrderIds',
    Cell: (cellInfo) =>
      cellInfo?.cell?.value && cellInfo?.cell?.value?.length > 0 ? (
        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 400 }}
          overlay={(props) => (
            <Tooltip id="button-tooltip" {...props}>
              <span>{cellInfo?.cell?.value?.join(', ')}</span>
            </Tooltip>
          )}>
          {cellInfo?.cell?.value?.length >= 2 ? (
            <p className="tooltip-data" style={{ cursor: 'pointer' }}>
              <span>{cellInfo?.cell?.value?.at(0)}</span>

              <b>,+ {cellInfo?.cell?.value?.length - 1} more...</b>
            </p>
          ) : (
            <span className="tooltip-data" style={{ cursor: 'pointer' }}>
              {cellInfo?.cell?.value?.at(0)}
            </span>
          )}
        </OverlayTrigger>
      ) : (
        '-'
      ),
    disableSortBy: true,
    isTooltipColumn: true,
  },
  {
    Header: 'Total Qty allocated',
    accessor: 'totalQty',
    Cell: (cellInfo) => <span>{convertToMt(cellInfo?.cell?.value ?? 0, 2)} MT</span>,
    disableSortBy: true,
  },
];

export const ALLOCATED_BATCH_COLUMN = [
  {
    Header: 'Batch ID',
    accessor: 'batchId',
    Cell: (cellInfo) => {
      const status = cellInfo?.data?.[cellInfo?.cell?.row?.index]?.status;
      const condition = status === 'REJECTED' || status === 'DELETED';
      return (
        <Link
          style={{
            pointerEvents: condition ? 'none' : '',
            color: condition ? 'gray' : '',
          }}
          to={`bulk-delivery/${cellInfo?.cell?.value}?status=ALLOCATED`}>
          {cellInfo?.cell?.value}
        </Link>
      );
    },
    isSticky: true,
    disableSortBy: true,
    Filter: ColumnFilters,
  },
  {
    Header: 'Batch Creation date',
    accessor: 'uploadedAt',
    Cell: (cellInfo) => <span>{toLocalDateTime(cellInfo?.cell?.value) ?? '-'}</span>,
    disableSortBy: true,
  },
  {
    Header: 'Username',
    accessor: 'username',
    Cell: (cellInfo) => (
      <TooltipHover
        value={
          cellInfo?.cell?.value
            ? cellInfo?.cell?.value?.length >= 25
              ? cellInfo?.cell?.value.slice(0, 25) + '...'
              : cellInfo?.cell?.value
            : '-'
        }
        component={<span>{cellInfo?.cell?.value}</span>}
      />
    ),
    disableSortBy: true,
    isTooltipColumn: true,
  },
  {
    Header: 'Zip file name',
    accessor: 'originalFileName',
    Cell: (cellInfo) => (
      <TooltipHover
        value={
          cellInfo?.cell?.value
            ? cellInfo?.cell?.value?.replace('.zip', '')?.length >= 25
              ? cellInfo?.cell?.value?.replace('.zip', '').slice(0, 25) + '...'
              : cellInfo?.cell?.value?.replace('.zip', '')
            : '-'
        }
        component={<span>{cellInfo?.cell?.value?.replace('.zip', '')}</span>}
      />
    ),
    isTooltipColumn: true,
    disableSortBy: true,
  },
  {
    id: 'recycler',
    Header: 'Vendor Name',
    accessor: 'serviceProviderName',
    Cell: (cellInfo) => {
      return (
        <TooltipHover
          value={
            cellInfo?.cell?.value
              ? cellInfo?.cell?.value?.length >= 25
                ? cellInfo?.cell?.value.slice(0, 25) + '...'
                : cellInfo?.cell?.value
              : '-'
          }
          component={<span>{cellInfo?.cell?.value}</span>}
        />
      );
    },
    disableSortBy: true,
    Filter: ColumnFilters,
    isTooltipColumn: true,
  },
  {
    Header: 'Workorder ID',
    accessor: 'workOrderIds',
    Cell: (cellInfo) =>
      cellInfo?.cell?.value && cellInfo?.cell?.value?.length > 0 ? (
        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 400 }}
          overlay={(props) => (
            <Tooltip id="button-tooltip" {...props}>
              <span>{cellInfo?.cell?.value?.join(', ')}</span>
            </Tooltip>
          )}>
          {cellInfo?.cell?.value?.length >= 2 ? (
            <p className="tooltip-data" style={{ cursor: 'pointer' }}>
              <span>{cellInfo?.cell?.value?.at(0)}</span>

              <b>,+ {cellInfo?.cell?.value?.length - 1} more...</b>
            </p>
          ) : (
            <span className="tooltip-data" style={{ cursor: 'pointer' }}>
              {cellInfo?.cell?.value?.at(0)}
            </span>
          )}
        </OverlayTrigger>
      ) : (
        '-'
      ),
    disableSortBy: true,
    isTooltipColumn: true,
  },
  {
    Header: 'Total Qty allocated',
    accessor: 'totalQty',
    Cell: (cellInfo) => <span>{convertToMt(cellInfo?.cell?.value ?? 0, 2)} MT</span>,
    disableSortBy: true,
  },
];

export const REJECTED_BATCH_COLUMN_PRO = [
  {
    Header: 'Batch ID',
    accessor: 'batchId',
    isSticky: true,
    disableSortBy: true,
    Filter: ColumnFilters,
  },
  {
    Header: 'Batch Creation Date',
    accessor: 'uploadedAt',
    Cell: (cellInfo) => {
      return <span>{toLocalDateTime(cellInfo?.cell?.value)}</span>;
    },
    disableSortBy: true,
  },
  {
    Header: 'Username',
    accessor: 'username',
    Cell: (cellInfo) => {
      return (
        <TooltipHover
          value={
            cellInfo?.cell?.value
              ? cellInfo?.cell?.value?.length >= 25
                ? cellInfo?.cell?.value.slice(0, 25) + '...'
                : cellInfo?.cell?.value
              : '-'
          }
          component={<span>{cellInfo?.cell?.value}</span>}
        />
      );
    },
    disableSortBy: true,
    isTooltipColumn: true,
  },
  {
    Header: 'Service Provider',
    accessor: 'serviceProviderName',
    Cell: (cellInfo) => {
      return (
        <TooltipHover
          value={
            cellInfo?.cell?.value
              ? cellInfo?.cell?.value?.length >= 25
                ? cellInfo?.cell?.value.slice(0, 25) + '...'
                : cellInfo?.cell?.value
              : '-'
          }
          component={<span>{cellInfo?.cell?.value}</span>}
        />
      );
    },
    Filter: ColumnFilters,
    isTooltipColumn: true,
    disableSortBy: true,
  },
  {
    Header: 'Recycler',
    accessor: 'recyclerName',
    Cell: (cellInfo) => {
      return (
        <TooltipHover
          value={
            cellInfo?.cell?.value
              ? cellInfo?.cell?.value?.length >= 25
                ? cellInfo?.cell?.value.slice(0, 25) + '...'
                : cellInfo?.cell?.value
              : '-'
          }
          component={<span>{cellInfo?.cell?.value}</span>}
        />
      );
    },
    Filter: ColumnFilters,
    isTooltipColumn: true,
    disableSortBy: true,
  },
  {
    Header: 'ZIP File name',
    accessor: 'originalFileName',
    Cell: (cellInfo) => {
      return (
        <TooltipHover
          value={
            cellInfo?.cell?.value
              ? cellInfo?.cell?.value?.replace('.zip', '')?.length >= 25
                ? cellInfo?.cell?.value?.replace('.zip', '').slice(0, 25) + '...'
                : cellInfo?.cell?.value?.replace('.zip', '')
              : '-'
          }
          component={<span>{cellInfo?.cell?.value?.replace('.zip', '')}</span>}
        />
      );
    },
    disableSortBy: true,
    isTooltipColumn: true,
  },
  {
    Header: 'Reject Reasons',
    accessor: 'remarks',
    Cell: (cellInfo) => {
      return (
        <TooltipHover
          value={
            cellInfo?.cell?.value
              ? cellInfo?.cell?.value?.length >= 25
                ? cellInfo?.cell?.value.slice(0, 25) + '...'
                : cellInfo?.cell?.value
              : '-'
          }
          component={<span>{cellInfo?.cell?.value}</span>}
        />
      );
    },
    disableSortBy: true,
    isTooltipColumn: true,
  },
];

export const REJECTED_BATCH_COLUMN = [
  {
    Header: 'Batch ID',
    accessor: 'batchId',
    Cell: (cellInfo) => {
      return <span>{cellInfo?.cell?.value}</span>;
    },
    isSticky: true,
    disableSortBy: true,
    Filter: ColumnFilters,
  },
  {
    Header: 'Batch Creation Date',
    accessor: 'uploadedAt',
    Cell: (cellInfo) => {
      return <span>{toLocalDateTime(cellInfo?.cell?.value)}</span>;
    },
    disableSortBy: true,
  },
  {
    Header: 'Username',
    accessor: 'username',
    Cell: (cellInfo) => {
      return (
        <TooltipHover
          value={
            cellInfo?.cell?.value
              ? cellInfo?.cell?.value?.length >= 25
                ? cellInfo?.cell?.value.slice(0, 25) + '...'
                : cellInfo?.cell?.value
              : '-'
          }
          component={<span>{cellInfo?.cell?.value}</span>}
        />
      );
    },
    disableSortBy: true,
    isTooltipColumn: true,
  },
  {
    Header: 'Service Provider',
    accessor: 'serviceProviderName',
    Cell: (cellInfo) => {
      return (
        <TooltipHover
          value={
            cellInfo?.cell?.value
              ? cellInfo?.cell?.value?.length >= 25
                ? cellInfo?.cell?.value.slice(0, 25) + '...'
                : cellInfo?.cell?.value
              : '-'
          }
          component={<span>{cellInfo?.cell?.value}</span>}
        />
      );
    },
    Filter: ColumnFilters,
    isTooltipColumn: true,
    disableSortBy: true,
  },
  {
    Header: 'ZIP File name',
    accessor: 'originalFileName',
    Cell: (cellInfo) => {
      return (
        <TooltipHover
          value={
            cellInfo?.cell?.value
              ? cellInfo?.cell?.value?.replace('.zip', '')?.length >= 25
                ? cellInfo?.cell?.value?.replace('.zip', '').slice(0, 25) + '...'
                : cellInfo?.cell?.value?.replace('.zip', '')
              : '-'
          }
          component={<span>{cellInfo?.cell?.value?.replace('.zip', '')}</span>}
        />
      );
    },
    disableSortBy: true,
    isTooltipColumn: true,
  },
  {
    Header: 'Reject Reasons',
    accessor: 'remarks',
    Cell: (cellInfo) => {
      return (
        <TooltipHover
          value={
            cellInfo?.cell?.value
              ? cellInfo?.cell?.value?.length >= 25
                ? cellInfo?.cell?.value.slice(0, 25) + '...'
                : cellInfo?.cell?.value
              : '-'
          }
          component={<span>{cellInfo?.cell?.value}</span>}
        />
      );
    },
    isTooltipColumn: true,
    disableSortBy: true,
  },
];

export const ALLOCATED_SUMMARY = [
  {
    Header: 'Service Provider',
    accessor: 'spName',
    disableSortBy: true,
  },
  {
    Header: 'Recycler',
    accessor: 'recyName',
    disableSortBy: true,
  },
  {
    Header: 'Allocation Date & Time',
    accessor: 'allocatedDate',
    disableSortBy: true,
  },
  {
    Header: 'Work Order',
    accessor: 'woId',

    disableSortBy: true,
  },
  {
    Header: 'DLs Allocated',
    accessor: 'dlCount',

    disableSortBy: true,
  },
  {
    Header: 'Allocated QTY',
    accessor: 'allocatedQty',
    Cell: (cellInfo) => <span>{convertToMt(cellInfo?.cell?.value ?? 0, 3) ?? '-'} MT</span>,
    disableSortBy: true,
  },
];
