import isEqual from 'lodash/isEqual';
import uniq from 'lodash/uniq';
import * as moment from 'moment';

export const validateAndReturnInvalidRowIndexes = (formDate) => {
  const invalidRows = [...checkDuplicates(formDate)];
  return uniq(invalidRows);
};

const checkDuplicates = (formData) => {
  const arrOfDuplicates = [];
  const arr = formData.workOrderItems.map((item) => {
    return {
      browseNodeId: item.browseNodeId,
      itemId: item.itemId,
      stateId: item.stateId,
      districtId: item.districtId,
      creditType: item.creditType ? item.creditType : '',
    };
  });
  for (let i = 0; i < arr.length; i++) {
    for (let j = i + 1; j < arr.length; j++) {
      if (isEqual(arr[i], arr[j])) {
        arrOfDuplicates.push(j);
      }
    }
  }
  return uniq(arrOfDuplicates);
};

const validateEstimatedDeliveryDate = (formData) => {
  const rowsToReturn = [];
  formData.workOrderItems.forEach((item, index) => {
    if (
      formData.fromDate &&
      formData.toDate &&
      !moment(item.estimatedDelivery).isBetween(moment(formData.fromDate), moment(formData.toDate))
    ) {
      rowsToReturn.push(index);
    }
  });
  return rowsToReturn;
};
