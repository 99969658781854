import { faChevronLeft, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormik } from 'formik';
import { isEmpty, isNull } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Badge, Button, Card, Col, Form, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { StringParam, useQueryParam } from 'use-query-params';
import * as Yup from 'yup';
import AccessDenied from '../../../features/Others/AccessDenied/AccessDenied';
import BrandWorkOrderItemsEdit from '../../../features/WorkOrder/WorkOrderItemsEdit/BrandWorkOrderItemsEdit';
import { WorkOrderService } from '../../../features/WorkOrder/workOrder.service';
import { useAccount } from '../../../hooks';
import { useServiceProviders } from '../../../hooks/useCustomers';
import { useBrowseNodeMap, useMaterialCategory } from '../../../hooks/useMaterialCategory';
import { useRegions } from '../../../hooks/useRegions';
import { DropdownSelect, RcylDatePicker } from '../../../shared/Components';
import { useAuthority } from '../../../shared/Components/HasAuthority/HasAuthorityNew';
import Loader from '../../../shared/Components/Loader/Loader';
// import UploadScrollableCard from '../../../shared/Components/UploadScrollableCard/UploadScrollableCard';
import ArrowUpRight from '../../../assets/imageComponents/arrow-up-right';
import { getClientsById } from '../../../features/Customers/api_calls';
import CommentsModal from '../../../features/WorkOrder/WorkOrderItemsEdit/CommentsModal';
import PromptInputModal from '../../../features/WorkOrder/WorkOrderItemsEdit/PromptInputModal';
import { useAsyncEffect } from '../../../hooks';
import PromptModal from '../../../shared/Components/PromptModal/PromptModal';
import MultiUploadCard from '../../../shared/Components/UploadScrollableCard/MultiUploadCardNew';
import {
  AUTHORITY2,
  ENTITY_TYPE_OPTIONS,
  FULFILLMENT_CYCLES,
  TRACEABILITY_DOCS_OPTIONS,
  WORK_ORDER_DISPLAY_STATUS,
} from '../../../shared/Constants/Constants';
import { toLocalDate } from '../../../shared/Utils/Helper';
import { roundOffAmount } from '../../../shared/Utils/Utils';
import './WorkOrderCreate.scss';
import { validateAndReturnInvalidRowIndexes } from './WorkOrderCreateFormValidators';
const checkSpecificValuesNotEmptyInArray = (arr, fields) => {
  return arr.every((obj) =>
    fields.every((field) => obj[field] !== undefined && obj[field] !== null && obj[field] !== '')
  );
};
const WorkOrderCreateBrandPRO = (props) => {
  const workOrderId = props.match.params.id;
  const { itemsById } = useBrowseNodeMap();
  const account = useAccount();

  let [isEdit] = useQueryParam('isEdit', StringParam);

  const history = useHistory();
  const { hasAuth } = useAuthority();
  const isUserServiceProvider = hasAuth([AUTHORITY2.SERVICE_PROVIDER, AUTHORITY2.PRO]);
  const isUserPRO = hasAuth([AUTHORITY2.PRO]);
  const isBrand = hasAuth([AUTHORITY2.BRAND]);
  const [brandDetails, setBrandDetails] = useState({});
  const [loadingBrandDetails, setLoadingBrandDetails] = useState(false);
  const [maxValueByCategory, setMaxValueByCategory] = useState({
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    6: 0,
    7: 0,
  });

  const NEW_ROW = {
    workOrderFor: null,
    browseNodeId: null,
    itemId: null,
    stateId: null,
    districtId: null,
    pricePerUnit: null,
    lineTotal: '',
    qty: 0,
    estimatedDelivery: '',
    fulfillmentCycle: '',
    unallocatedTarget: '',
    itemUnit: '',
    poLineItem: account?.user?.profile?.id === 9053 ? '' : undefined,
    sac: null,
    taxRate: null,
    plannedQty: 0,
    fulfilledQty: 0,
  };
  const [renderCount, setRenderCount] = useState(0);
  const [rowsToHighlight, setRowsHighlight] = useState([]);
  const [updateSaveInProgress, setUpdateSaveInProgress] = useState(false);
  const [deliveryExists, setDeliveryExists] = useState(false);
  const [originalWorkOrderData, setOriginalWorkOrderData] = useState({});
  const [poDate, setPoDate] = useState('');
  const [commentValidation, setCommentValidation] = useState();
  const [orderSummary, setOrderSummary] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [uploadedDocs, setUploadedDocs] = useState();
  const [isLoading, setIsLoading] = useState(isEdit);
  const [incompleteStatus, setIncompleteStatus] = useState();
  const [isPlannedQtyValid, setIsPlannedQtyValid] = useState(false);
  const [isInComplete, setIsIncomplete] = useState(false);
  const [saveEditedDetails, setSaveEditedDetails] = useState(false);
  const [SaveEditText, setSaveEditText] = useState('');
  const [goBack, setGoBack] = useState(false);

  const serviceProviders = useServiceProviders(true);
  const { hasPermission } = useAuthority();

  const { clients, financialYears } = useSelector(({ account }) => {
    return { clients: account.clients, financialYears: account?.financialYears };
  });

  const browseNodes = useMaterialCategory();
  const regions = useRegions();

  useEffect(() => {
    if (workOrderId) {
      doGetWorkOrderByID();
    }
    setRenderCount(renderCount + 1);
  }, []);

  const doGetWorkOrderByID = async () => {
    try {
      const response = await WorkOrderService.fetchWorkOrderById(workOrderId);
      if (response?.data?.plannedQty > 0) {
        setIsPlannedQtyValid(true);
      }

      if (response?.data?.status === 'IN_COMPLETE') {
        setIsIncomplete(true);
      }

      setIncompleteStatus(response?.data?.status);
      if (response?.data) {
        if (isEdit) {
          response.data.workOrderItems.forEach(function (item) {
            item.fulQty = item.fulfilledQty;
            if (item.fulfilledQty !== null && item.fulfilledQty > 0) {
              setDeliveryExists(true);
            }
          });
        }
        const maxValueMap = {
          1: 0,
          2: 0,
          3: 0,
          4: 0,
          5: 0,
          6: 0,
          7: 0,
        };
        response.data.workOrderItems.forEach((li) => {
          maxValueMap[li.browseNodeId] += li.qty;
        });
        setMaxValueByCategory(maxValueMap);

        setOriginalWorkOrderData({
          ...response?.data,
          fulfilledYear: getFulFillyearLabel(response?.data?.fulfillmentYearStartDate),
          fulfillmentCycle: response?.data?.workOrderItems?.[0]?.fulfillmentCycle,
          workOrderItems: response?.data?.workOrderItems?.map((wo_item) => ({
            ...wo_item,
            stateCode: wo_item?.stateCode !== null ? wo_item?.stateCode : 0,
            stateId: wo_item?.stateId !== null ? wo_item?.stateId : 0,
          })),
        });
      }
    } catch (e) {}
    setIsLoading(false);
  };

  function isValidEntity(value) {
    if (isEmpty(brandDetails)) return true;
    if (!brandDetails?.customerPiboDetails?.length) return false;
    const currentPibo = brandDetails?.customerPiboDetails
      ?.filter((piboObject) => piboObject?.active)
      ?.filter((piboObject) => piboObject.type === value?.replace(' ', '_')?.toUpperCase())
      ?.at(0);
    if (!currentPibo?.cpcbWalletNumber) {
      return false;
    }
    return true;
  }

  const workOrderForm = useFormik({
    enableReinitialize: isEdit,
    initialValues: {
      fromCustomerId: isBrand ? account?.user?.profile?.id : null,
      fromDate: '',
      toCustomerId: '',
      customerType: 'EPR',
      entityType: '',
      traceabilityDocuments: null,
      toBrandId: '',
      brandId: '',
      toDate: '',
      poNumber: '',
      poDate: '',
      paymentTerm: null,
      draft: false,
      isSaveButtonDisabled: false,
      workOrderItems: [NEW_ROW],
      documents: [],
      fulfilledYear: '',
      fulfillmentYearStartDate: '',
      fulfillmentYearEndDate: '',
      comments: '',
      isInvoicing: null,
      extraComments: '',
      ...originalWorkOrderData,
    },
    validationSchema: Yup.object({
      fromDate: Yup.string('Invalid date.').required('Start date required.'),
      toDate: Yup.string('Invalid date.')
        .typeError('Estimated Delivery date required')
        .required('Estimated delivery date required.'),
      poNumber:
        incompleteStatus === 'IN_COMPLETE' || incompleteStatus === 'REJECTED'
          ? Yup.string('Invalid poNumber.').required('Po number is required')
          : Yup.string('Invalid poNumber.'),
      poDate:
        incompleteStatus === 'IN_COMPLETE' || incompleteStatus === 'REJECTED'
          ? Yup.string('Invalid poDate.').required('Po date is required')
          : Yup.string('Invalid poDate.'),
      paymentTerm: Yup.number('Invalid paymentTerm.').required('Payment Term required.'),
      entityType: Yup.string('Invalid entity type')
        .required('Entity type required')
        .test(
          'entity-should-have-data',
          'Importer Details are not available. Kindly update the information',
          function (value) {
            return isValidEntity(value);
          }
        ),
      fulfillmentYearStartDate: Yup.string('Invalid date ').required('Fulfillment year required'),
      fulfillmentYearEndDate: Yup.string('Invalid entity type'),
      traceabilityDocuments: Yup.boolean().required('Traceability documents is required'),
      id: Yup.string().nullable(true),
      draft: Yup.boolean().nullable(true),
      createdBy: Yup.number().nullable(true),
      totalAmount: Yup.number().nullable(true),
      fromCustomerId: Yup.number().required('Brand selection required'),
      workOrderType: Yup.string().nullable(true),
      status: Yup.string().nullable(true),
      createdOn: Yup.string().nullable(true),
      modifiedOn: Yup.string().nullable(true),
      workOrderItems: Yup.array()
        .of(
          Yup.object({
            browseNodeId: Yup.string().required(),
            itemId: Yup.string(),
            stateId: Yup.string().nullable(),
            districtId: Yup.string().nullable(),
            pricePerUnit: Yup.number().required().moreThan(0),
            lineTotal: Yup.number().required(),
            qty: Yup.number().required().moreThan(0),
            estimatedDelivery: Yup.string(),
            unallocatedTarget: Yup.number(),
            sac: null,
          })
            .test(
              'qty-greater-equal-to-planned',
              'Quantity cannot be less than the quantity already planned',
              function (value) {
                if (!isProgress) return true;
                return value?.qty >= (value?.plannedQty ?? 0);
              }
            )
            .test(
              'does-hul-have-poLineItem',
              'PO Line item is a mandatory field',
              function (value) {
                if (
                  !(
                    account?.user?.profile?.id === 9053 ||
                    workOrderForm?.values?.fromCustomerId === 9053
                  )
                )
                  return true;
                return !!value.poLineItem;
              }
            )
            .test(
              'credit-type-mandatory-for-plastics',
              'Credit type is a mandatory field for plastics',
              function (value) {
                if (!['1', '2', '4', '6'].includes(value?.browseNodeId)) return true;
                return !!value?.creditType;
              }
            )
        )
        .min(1)
        .test(
          'not-all-fields-same',
          'Two Line Items cannot have the same values for all fields',
          function (values) {
            if (!isProgress) return true;
            const createKey = (browseNodeId, itemId, stateId, districtId, creditType) => {
              return `${browseNodeId ?? ''} ${itemId ?? ''} ${stateId ?? ''} ${districtId ?? ''} ${
                creditType ?? null
              }`;
            };
            const map = new Map();
            let isValid = true;
            values.forEach((value) => {
              const key = createKey(
                value['browseNodeId'],
                value['itemId'],
                value['stateId'],
                value['districtId'],
                value['creditType']
              );
              isValid = isValid & !map.has(key);
              map.set(key, true);
            });

            return isValid;
          }
        )
        .test(
          'sum-for-category-less-than-max',
          'The total Quantity for a Category cannot be increased',
          function (value) {
            if (!isProgress) return true;
            const sumForCategory = {
              1: 0,
              2: 0,
              3: 0,
              4: 0,
              5: 0,
              6: 0,
              7: 0,
            };

            value.forEach((li) => (sumForCategory[li.browseNodeId] += li.qty));
            let isValid = true;
            Object.keys(sumForCategory).forEach((nodeId) => {
              isValid = isValid & (sumForCategory[nodeId] === maxValueByCategory[nodeId]);
            });
            return isValid;
          }
        ),
      documents:
        incompleteStatus === 'IN_COMPLETE'
          ? Yup.array().min(1, 'Atleast one doc is required')
          : null,
      extraComments: Yup.string(),
      ...commentValidation,
    }),
    isInitialValid: false,
    onSubmit: (values) => {
      prepareWorkOrderData({
        ...values,
      });
    },
  });

  useAsyncEffect(() => {
    WorkOrderService.getOrderSummary(
      account?.selectedFinancialYear?.fromDate,
      account?.selectedFinancialYear?.toDate
    ).then((os) => {
      setOrderSummary(os);
    });
  }, []);

  useEffect(() => {
    if (workOrderForm?.values?.fromCustomerId) {
      setLoadingBrandDetails(true);
      getClientsById(
        workOrderForm?.values?.fromCustomerId,
        (response) => {
          setBrandDetails(response.data);
          setLoadingBrandDetails(false);
          workOrderForm.validateForm();
        },
        (error) => {
          setLoadingBrandDetails(false);
        }
      );
    }
  }, [workOrderForm?.values?.fromCustomerId]);

  useEffect(() => {
    if (renderCount) {
      workOrderForm.validateForm().then();
      setRowsHighlight(validateAndReturnInvalidRowIndexes(workOrderForm.values));
    }
  }, [workOrderForm?.values]);

  useEffect(() => {
    if (financialYears) {
      const yearIndex = financialYears?.length - 3;
      const yearValue = financialYears[yearIndex];
      setPoDate(yearValue?.value?.fromDate);
    }
  }, [financialYears]);

  useEffect(() => {
    if (uploadedDocs?.length) {
      workOrderForm.setValues({ ...workOrderForm.values, documents: uploadedDocs });
      setUploadedDocs([]);
    }
  }, [uploadedDocs?.length]);

  const hasPlastic = workOrderForm?.values?.workOrderItems?.some((woItem) =>
    ['1', '2', '4', '6'].includes(woItem?.browseNodeId)
  );

  const prepareWorkOrderData = (requestData, shouldGoBack = true) => {
    requestData = {
      ...requestData,
      invoicing: requestData?.isInvoicing,
      workOrderItems: [...requestData?.workOrderItems]?.map((row) => {
        return {
          ...row,
          itemUnit:
            row?.itemUnit === '' || row?.itemUnit === 0 || row?.itemUnit === undefined
              ? null
              : row?.itemUnit,
          unallocatedTarget:
            row?.unallocatedTarget === '' ||
            row?.unallocatedTarget === 0 ||
            row?.unallocatedTarget === undefined
              ? null
              : row?.unallocatedTarget,
          qty: row?.qty === '' || row?.qty === 0 || row?.qty === undefined ? null : row?.qty,
          creditType:
            row?.creditType === '' ||
            row?.creditType === 'Select option' ||
            row?.creditType === undefined
              ? null
              : row?.creditType,
          fulfillmentCycle: workOrderForm?.values?.fulfillmentCycle,
          estimatedDelivery: workOrderForm?.values?.toDate,
          stateId: row?.stateId || null,
          taxRate: itemsById[row?.itemId]?.hsnCode?.tax,
          hsnCode: itemsById[row?.itemId]?.hsnCode?.id,
        };
      }),
    };

    if (!isUserServiceProvider) {
      requestData = {
        ...requestData,
        customerType: workOrderForm?.values?.customerType,
        entityType: workOrderForm?.values?.entityType,
        traceabilityDocuments: workOrderForm?.values?.traceabilityDocuments,
      };
      requestData.workOrderItems.forEach((item, itemIndex) => {
        requestData.workOrderItems[itemIndex].workOrderFor = account?.user?.profile?.id;
      });
    }
    createOrUpdateWorkOrder(requestData, requestData.id ? false : true, shouldGoBack);
  };

  const getFulFillyearLabel = (dateVal) => {
    const fulFilledYear = account?.financialYears?.find(
      (item) => toLocalDate(item?.value?.fromDate) === toLocalDate(dateVal)
    )?.label;

    return fulFilledYear;
  };

  const createOrUpdateWorkOrder = async (requestData, createOperation, shouldGoBack = true) => {
    setUpdateSaveInProgress(true);
    requestData.documents = workOrderForm.values.documents;
    try {
      const response = createOperation
        ? await WorkOrderService.createWorkOrder({
            ...requestData,
            toCustomerId: 8651,
            salesOrderId: originalWorkOrderData?.salesOrderId,
            salesOrderNumber: originalWorkOrderData?.salesOrderNumber,
          })
        : await WorkOrderService.updateWorkOrder({ ...requestData, toCustomerId: 8651 });
      if (response?.status === 200 || response?.status === 201) {
        workOrderForm.setValues({
          ...workOrderForm.values,
          draft: false,
          status: isProgress ? 'IN_PROGRESS' : hasPlastic ? 'IN_REVIEW' : 'IN_PROGRESS',
        });
        workOrderForm.resetForm({
          values: response.data,
          fulfilledYear: getFulFillyearLabel(response?.data?.fulfillmentYearStartDate),
          fulfillmentCycle: response?.data?.workOrderItems?.[0]?.fulfillmentCycle,
        });
        if (response?.data?.draft && shouldGoBack) {
          createOperation
            ? toast.success('Work order saved as draft successfully.')
            : toast.success('Work order draft updated successfully.');
          history.goBack();
        } else if (response?.data?.draft) {
          createOperation
            ? toast.success('Work order saved as draft successfully.')
            : toast.success('Work order draft updated successfully.');
        } else {
          toast.success(
            isProgress
              ? 'Work order updated successfully'
              : hasPlastic
              ? 'Work Order Submitted for review'
              : 'Work order created successfully'
          );
        }
        if (!createOperation) {
          workOrderForm.setFieldValue('documents', [...(response?.data?.documents || [])]);
        }

        if (!response?.data?.draft) {
          history.push(`/work-orders-brandPRO/${response?.data?.id}?isCreateUpdate=true`);
        }
      }
    } catch (e) {
      setUpdateSaveInProgress(false);
    }
    setUpdateSaveInProgress(false);
  };

  const updateDataToTable = (rowIndex, columnId, value) => {
    const tableData = [...workOrderForm.values.workOrderItems];
    workOrderForm.setFieldValue(
      'workOrderItems',
      tableData.map((row, index) => {
        if (index === rowIndex) {
          switch (columnId) {
            case 'browseNodeId':
              const rowData = tableData[rowIndex];
              return {
                ...rowData,
                [columnId]: value,
                lineTotal:
                  Number(tableData[rowIndex]?.pricePerUnit) * Number(tableData[rowIndex]?.qty),
                itemId: null,
                stateId: null,
                districtId: null,
              };
            case 'itemId':
              const rowValues = tableData[rowIndex];
              return {
                ...tableData[rowIndex],
                [columnId]: value,
                stateId: isEmpty(value) ? null : rowValues.stateId,
                districtId: isEmpty(value) ? null : rowValues.districtId,
              };
            case 'sac':
              return {
                ...tableData[rowIndex],
                [columnId]: value,
              };
            case 'pricePerUnit':
              return {
                ...tableData[rowIndex],
                [columnId]: Number(value),
                lineTotal: roundOffAmount(Number(value) * Number(tableData[rowIndex]?.qty)),
              };
            case 'qty':
              return {
                ...tableData[rowIndex],
                [columnId]: Number(parseFloat(value).toFixed(3)),
                lineTotal: roundOffAmount(
                  Number(parseFloat(value).toFixed(3)) * Number(tableData[rowIndex]?.pricePerUnit)
                ),
              };
            case 'poLineItem':
              return {
                ...tableData[rowIndex],
                [columnId]: Number(value),
              };
            case 'stateId':
              return {
                ...tableData[rowIndex],
                [columnId]: value ? Number(value) : value,
                districtId: '',
              };
            case 'districtId':
              return {
                ...tableData[rowIndex],
                [columnId]: value ? Number(value) : value,
              };
            case 'creditType':
              return {
                ...tableData[rowIndex],
                [columnId]: value,
              };
            default:
          }
          return {
            ...tableData[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      })
    );
  };

  const documentAttach = async (documentReqData) => {
    const response = await WorkOrderService.uploadDocuments(documentReqData);
    if (response?.data) {
      const existingValue = workOrderForm.values.documents || [];
      existingValue.push(response?.data);
      setUploadedDocs(existingValue);
    }
  };
  const handleDocumentChange = (uploadDocList) => {
    uploadDocList.forEach((newData) => {
      newData.entityId = workOrderId;
      documentAttach(newData);
    });
  };

  // const deletDocument = async (delDoc) => {

  //   try {
  //     // if(!delDoc?.id && delDoc?.fileName){
  //     //   let newselectedFiles = selectedFiles.filter((item) => item.fileName !== file?.fileName);
  //     //   setSelectedFiles(newselectedFiles);

  //     // }
  //     if (delDoc.id) {
  //       await WorkOrderService.deleteWorkOrderDocument(delDoc.id);
  //     }
  //     let index = null;
  //     const doc = workOrderForm?.values?.documents || [];
  //     if (!!delDoc.id) {
  //       index = doc?.findIndex((e) => e.id === delDoc.id);
  //     } else {
  //       index = doc?.findIndex((e) => e.fileName === delDoc.fileName);

  //     }
  //     if (index !== -1) {
  //       const updatedDocuments = [...doc.slice(0, index), ...doc.slice(index + 1)];
  //       workOrderForm.setFieldValue('documents', updatedDocuments);
  //     } else {
  //       workOrderForm.setFieldValue('documents', doc);

  //     }
  //    // doc.splice(index, 1);
  //     // workOrderForm.setFieldValue('documents', doc);
  //   } catch (e) {
  //
  //   }
  // };

  const deleteDocument = async (delDoc) => {
    if (delDoc?.id) {
      await WorkOrderService.deleteWorkOrderDocument(delDoc.id);
    }
    let doc = workOrderForm?.values?.documents || [];
    const updatedDocuments = doc.filter((e) => {
      if (delDoc?.id) {
        return e.id !== delDoc.id;
      } else if (delDoc?.viewUrl) {
        return e.viewUrl !== delDoc.viewUrl;
      } else if (delDoc?.fileName) {
        return e.fileName !== delDoc.fileName;
      }
    });
    workOrderForm.setFieldValue('documents', updatedDocuments);
  };

  const deleteRow = (rowIndex) => {
    const workOrderItems = [...workOrderForm.values.workOrderItems];
    workOrderItems.splice(rowIndex, 1);
    workOrderForm.setFieldValue('workOrderItems', [...workOrderItems]);
  };

  const addOrCopyRow = (rowIndex) => {
    let newRow = { ...NEW_ROW };
    const { workOrderFor, browseNodeId, itemId, estimatedDelivery, fulfillmentCycle } =
      workOrderForm?.values?.workOrderItems[rowIndex] || [];
    if (workOrderForm.values.toDate) {
      newRow.estimatedDelivery = workOrderForm.values.toDate;
    }
    workOrderForm.setFieldValue('workOrderItems', [
      ...workOrderForm.values.workOrderItems,
      {
        ...NEW_ROW,
        ...{
          workOrderFor: workOrderFor,
          browseNodeId: browseNodeId,
          itemId: itemId || null,
          estimatedDelivery: estimatedDelivery,
          fulfillmentCycle: fulfillmentCycle ?? getFulfillmentCycle(),
        },
      },
    ]);
  };

  const getFulfillmentCycle = () => {
    let cycle = '';
    if (!!workOrderForm.values.toCustomerId) {
      cycle =
        serviceProviders.find((e) => e.id === Number(workOrderForm.values.toCustomerId))
          ?.fulfillmentcycle || '';
    }
    return cycle;
  };

  const dropDownHandler = (a) => {
    workOrderForm.handleChange(a);
  };

  const finYearHandler = (e) => {
    const DATES = e?.target?.value;

    const fulFilledYear = account?.financialYears?.find((item) => item?.label === DATES);

    workOrderForm.setValues({
      ...workOrderForm?.values,
      fulfillmentYearStartDate: fulFilledYear?.value?.fromDate,
      fulfillmentYearEndDate: fulFilledYear?.value?.toDate,
      fulfilledYear: fulFilledYear?.label,
    });
  };

  const isDataNotValid =
    rowsToHighlight.length ||
    !workOrderForm?.dirty ||
    updateSaveInProgress ||
    workOrderForm.values?.workOrderItems?.length < 1 ||
    !workOrderForm?.values.toDate ||
    !workOrderForm?.values.fromDate ||
    !workOrderForm?.values.fromCustomerId ||
    !checkSpecificValuesNotEmptyInArray(workOrderForm.values.workOrderItems, [
      'browseNodeId',
      'itemId',
    ]);

  const handlesaveEditedDetails = () => {
    setSaveEditedDetails((pre) => !pre);
    setSaveEditText('');
  };

  const submitData = () => {
    prepareWorkOrderData({
      ...workOrderForm.values,
      remarks: SaveEditText ? SaveEditText : '',
      draft: false,
      status: isProgress ? 'IN_PROGRESS' : hasPlastic ? 'IN_REVIEW' : 'IN_PROGRESS',
    });
  };

  const ModalsavedEditedDetails = () => {
    handlesaveEditedDetails();
    submitData();
  };

  const handleKeyDown = (event) => {
    const invalidCharecters = ['-', '+', 'e'];
    if (invalidCharecters.includes(event?.key)) {
      event.preventDefault();
    }
  };

  const hasPurchaseOrderDoc = workOrderForm?.values?.documents?.filter(
    (e) => e.type === 'WO_PURCHASE_ORDER'
  )?.length;

  const handleClientHandler = (e) => {
    workOrderForm.setFieldValue('fromCustomerId', e.id);
  };

  const isRejected = workOrderForm?.values?.status === 'REJECTED';
  const isDraft = workOrderForm?.values?.status === 'DRAFT';
  const isProgress = workOrderForm?.values?.status === 'IN_PROGRESS';

  const canSave =
    isBrand ||
    (isUserPRO &&
      hasPermission({
        permissionsAllowed: [
          {
            groupName: 'Execute_Targets',
            moduleName: 'Work_Orders',
            name: isEdit ? 'can_edit' : 'can_create',
          },
        ],
      }));

  const handleClose = () => setModalShow(false);
  const handlegoBack = () => {
    setGoBack((pre) => !pre);
  };
  const goToHistory = () => {
    isBrand ? history.push('/work-orders') : history.push('/work-orders-incoming');
  };

  const isPlanningDone = !workOrderForm?.values?.workOrderItems?.some(
    (li) => li.plannedQty !== li.qty
  );

  const isFulfillmentStarted = workOrderForm?.values?.workOrderItems?.some(
    (li) => li.fulfilledQty !== 0
  );
  const isFulfillmentDone = !workOrderForm?.values?.workOrderItems?.some(
    (li) => li.fulfilledQty !== li.qty
  );

  const inCompleteDisabled = (isProgress && isPlanningDone) || (isPlannedQtyValid && isInComplete);

  return (
    <div className={'animated fadeIn'}>
      <React.Suspense fallback={<Loader className="page-loading" />}>
        {isLoading ? (
          <Loader className="page-loading" />
        ) : (
          <Card className="main-card ">
            <Form id="createWorkOrderForm" onSubmit={(e) => workOrderForm.handleSubmit(e)}>
              <Card.Header className="bg-white border-0  py-0">
                <Row className="padding-aligned align-self-center">
                  <Col className="align-self-center" xs={'3'}>
                    <div className={'d-flex align-items-center'}>
                      <Button
                        className={'mr-2'}
                        onClick={workOrderForm?.dirty ? handlegoBack : goToHistory}>
                        <FontAwesomeIcon icon={faChevronLeft} />
                      </Button>
                      {workOrderForm?.values?.id ? (
                        <span className=" wotitleflex">
                          <h4> {workOrderForm?.values?.id} </h4>
                          <Badge
                            style={{
                              backgroundColor:
                                WORK_ORDER_DISPLAY_STATUS?.[workOrderForm?.values?.status]?.bg,
                              color:
                                WORK_ORDER_DISPLAY_STATUS?.[workOrderForm?.values?.status]
                                  ?.textColor,
                              border: `1px solid ${
                                WORK_ORDER_DISPLAY_STATUS?.[workOrderForm?.values?.status]
                                  ?.borderColor
                              }`,
                              borderRadius: '16px',
                              padding: '6px',
                              fontSize: '12px',
                              fontWeight: 500,
                              textAlign: 'center',
                              fontFamily: 'Inter',
                            }}>
                            {
                              WORK_ORDER_DISPLAY_STATUS?.[workOrderForm?.values?.status]
                                ?.displayText
                            }
                          </Badge>
                        </span>
                      ) : (
                        <h4 className="">Create Work Order</h4>
                      )}
                    </div>
                  </Col>
                  <Col className="pl-0 text-right justify-content-end">
                    <Button
                      className={'discard_button_brand mr-3'}
                      variant={'outline'}
                      onClick={workOrderForm?.dirty ? handlegoBack : goToHistory}>
                      Discard
                    </Button>

                    {((!isProgress && isEdit && !isRejected) ||
                      isDraft ||
                      (!isEdit &&
                        !['IN_PROGRESS', 'IN_REVIEW', 'CLOSED'].includes(
                          workOrderForm?.values?.status
                        ))) && (
                      <Button
                        className={'discard_button_brand mr-3'}
                        variant={'outline'}
                        disabled={isDataNotValid}
                        onClick={() => {
                          workOrderForm.setFieldValue(
                            'draft',
                            workOrderForm?.values?.status === 'IN_COMPLETE' ? false : true
                          );
                          prepareWorkOrderData({
                            ...workOrderForm?.values,
                            draft: workOrderForm?.values?.status === 'IN_COMPLETE' ? false : true,
                          });
                        }}>
                        {workOrderForm?.values?.status === 'IN_COMPLETE' ? 'Save' : 'Save as Draft'}
                      </Button>
                    )}

                    {canSave && (
                      // <Button
                      //   className="save_button_brand"
                      //   disabled={
                      //     // !hasPurchaseOrderDoc ||
                      //     (!isDraft && !workOrderForm?.dirty) ||
                      //     !workOrderForm?.values?.workOrderItems?.length ||
                      //     rowsToHighlight.length ||
                      //     updateSaveInProgress ||
                      //     !isEmpty(workOrderForm?.errors) ||
                      //     workOrderForm?.values?.isSaveButtonDisabled
                      //   }
                      //   onClick={isProgress && isEdit ? handlesaveEditedDetails : submitData}
                      //   // type='submit'Submit for Review
                      // >
                      //   {isProgress
                      //     ? 'Update Work Order'
                      //     : hasPlastic
                      //     ? 'Submit for Review'
                      //     : 'Create Work Order'}
                      // </Button>

                      <Button
                        className="save_button_brand"
                        disabled={
                          (!isDraft && !workOrderForm?.dirty) ||
                          !workOrderForm?.values?.workOrderItems?.length ||
                          rowsToHighlight.length ||
                          updateSaveInProgress ||
                          (!isDraft &&
                            (!workOrderForm?.values?.poNumber ||
                              !workOrderForm?.values?.poDate ||
                              !(
                                workOrderForm?.values?.documents &&
                                workOrderForm?.values?.documents.length
                              ) ||
                              !isEmpty(workOrderForm?.errors))) ||
                          workOrderForm?.values?.isSaveButtonDisabled
                        }
                        onClick={isProgress && isEdit ? handlesaveEditedDetails : submitData}>
                        {isProgress
                          ? 'Update Work Order'
                          : hasPlastic
                          ? 'Submit for Review'
                          : 'Create Work Order'}
                      </Button>
                    )}
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body className={'p-0'}>
                {canSave ? (
                  <Row>
                    <Col xs={12}>
                      <div style={{ padding: '2rem 2rem 0rem 2rem' }}>
                        <Row>
                          <Col md={6}>
                            <h5 className="mb-1">Provide Work Order Details</h5>
                          </Col>
                          <Col md={6} className="text-right mb-3">
                            <Button
                              variant="outline"
                              onClick={() => setModalShow(true)}
                              className="comments_btn_brand"
                              style={{ border: '1px solid #D0D5DD' }}>
                              {workOrderId && workOrderForm.values?.extraComments
                                ? 'Edit Comments'
                                : ' Add Comments'}
                            </Button>
                          </Col>
                        </Row>
                        {workOrderForm?.values?.fromCustomerId &&
                          workOrderForm?.values?.entityType &&
                          !isValidEntity(workOrderForm?.values?.entityType) && (
                            <div className="invalid-entity-div">
                              {workOrderForm?.values?.entityType} CPCB details are not available.
                              Kindly update the information.
                              <div
                                onClick={() => {
                                  const fromCustomerId = workOrderForm?.values?.fromCustomerId;
                                  if (
                                    !isDataNotValid &&
                                    ((!isProgress && isEdit && !isRejected) ||
                                      isDraft ||
                                      (!isEdit &&
                                        !['IN_PROGRESS', 'IN_REVIEW', 'CLOSED'].includes(
                                          workOrderForm?.values?.status
                                        )))
                                  ) {
                                    workOrderForm.setFieldValue('draft', true);
                                    prepareWorkOrderData(
                                      { ...workOrderForm?.values, draft: true },
                                      false
                                    );
                                  }
                                  history.push(`/client/${fromCustomerId}?editCPCB=true`);
                                }}>
                                Click here
                                <ArrowUpRight />
                              </div>
                            </div>
                          )}
                        <Row className={'details-filter'}>
                          <Col xs={12} sm={12} md={12} lg={8.5} xl={8} className={'pt-2'}>
                            <Row>
                              {isUserPRO ? (
                                <Col xs={3}>
                                  <Form.Group>
                                    <Form.Label className="label_wodetails">
                                      Brand <span className="mandatory-astrik-color">*</span>
                                    </Form.Label>
                                    <DropdownSelect
                                      className="form-control-one"
                                      name="fromCustomerId"
                                      id="fromCustomerId"
                                      onChange={(e) => handleClientHandler(e)}
                                      isDisabled={isProgress || isPlannedQtyValid}
                                      value={workOrderForm?.values?.fromCustomerId}
                                      options={clients}
                                      getOptionLabel={(option) => `${option.name}`}
                                      getOptionValue={(option) => `${option.id}`}
                                    />
                                  </Form.Group>
                                </Col>
                              ) : null}
                              <Col xs={3}>
                                <Form.Group
                                  className="date-picker-bg-img-icon left-picker "
                                  id="startDate">
                                  <Form.Label className="label_wodetails">
                                    Start Date <span className="mandatory-astrik-color">*</span>
                                  </Form.Label>
                                  <RcylDatePicker
                                    // parentEl="#startDate"
                                    showDropdowns={true}
                                    singleDatePicker
                                    disabled={
                                      (isProgress && isPlanningDone) ||
                                      (isPlannedQtyValid && isInComplete)
                                    }
                                    startDate={workOrderForm.values.fromDate}
                                    onApply={({ startDate, endDate }, $event) => {
                                      !deliveryExists &&
                                        workOrderForm.setValues({
                                          ...workOrderForm?.values,
                                          fromDate: startDate,
                                          toDate: null,
                                        });
                                    }}
                                    isInvalid={!!workOrderForm.errors.fromDate}
                                    errorMessage={workOrderForm.errors.fromDate}
                                  />
                                </Form.Group>
                              </Col>
                              <Col xs={3}>
                                <Form.Group
                                  className="date-picker-bg-img-icon left-picker "
                                  id="toDate">
                                  <Form.Label className="label_wodetails">
                                    Estimated delivery date{' '}
                                    <span className="mandatory-astrik-color">*</span>
                                  </Form.Label>
                                  <RcylDatePicker
                                    minDate={workOrderForm.values.fromDate}
                                    singleDatePicker
                                    showDropdowns={true}
                                    disabled={
                                      (isProgress && isPlanningDone) ||
                                      (isPlannedQtyValid && isInComplete)
                                    }
                                    startDate={workOrderForm.values.toDate ?? ''}
                                    onApply={({ startDate, endDate }, $event) => {
                                      !deliveryExists &&
                                        workOrderForm.setFieldValue('toDate', startDate);
                                    }}
                                    isInvalid={!!workOrderForm.errors.toDate}
                                    errorMessage={workOrderForm.errors.toDate}
                                  />
                                </Form.Group>
                              </Col>
                              <Col xs={3}>
                                <Form.Group>
                                  <Form.Label className="label_wodetails">
                                    Entity Type <span className="mandatory-astrik-color">*</span>
                                  </Form.Label>
                                  <Form.Control
                                    as="select"
                                    className="px-3 form-control-one"
                                    name="entityType"
                                    id="entityType"
                                    loading={loadingBrandDetails}
                                    disabled={
                                      (!!workOrderForm?.initialValues?.entityType &&
                                        isProgress &&
                                        isFulfillmentStarted) ||
                                      loadingBrandDetails ||
                                      (isPlannedQtyValid && isInComplete)
                                    }
                                    onChange={dropDownHandler}
                                    isInvalid={!!workOrderForm.errors.entityType}
                                    value={workOrderForm?.values?.entityType}>
                                    <option key={'ENTITY_TYPE_NONE'} value="">
                                      Select option
                                    </option>
                                    {ENTITY_TYPE_OPTIONS.map((type) => {
                                      return (
                                        <option key={type?.id} value={type?.label}>
                                          {type?.label}
                                        </option>
                                      );
                                    })}
                                  </Form.Control>
                                </Form.Group>
                              </Col>
                              <Col xs={3}>
                                <Form.Group
                                  className="date-picker-bg-img-icon left-picker "
                                  id="deliveryDate">
                                  <Form.Label className="label_wodetails">
                                    PO Date <span className="mandatory-astrik-color">*</span>
                                  </Form.Label>
                                  <RcylDatePicker
                                    disabled={isProgress && !!workOrderForm?.initialValues?.poDate}
                                    minDate={poDate}
                                    singleDatePicker
                                    startDate={workOrderForm.values.poDate}
                                    onApply={({ startDate, endDate }, $event) => {
                                      workOrderForm.setFieldValue('poDate', startDate);
                                    }}
                                    showDropdowns={true}
                                    isInvalid={!!workOrderForm.errors.poDate}
                                    errorMessage={workOrderForm.errors.poDate}
                                  />
                                </Form.Group>
                              </Col>
                              <Col xs={3}>
                                <Form.Group>
                                  <Form.Label className="label_wodetails">
                                    PO Number <span className="mandatory-astrik-color">*</span>
                                  </Form.Label>
                                  <OverlayTrigger
                                    placement="top"
                                    delay={{ show: 100, hide: 400 }}
                                    overlay={
                                      <Tooltip id="button-tooltip">
                                        This is external Reference ID
                                      </Tooltip>
                                    }>
                                    <FontAwesomeIcon
                                      className="text-muted ml-1"
                                      icon={faQuestionCircle}
                                    />
                                  </OverlayTrigger>
                                  <Form.Control
                                    name="poNumber"
                                    id="poNumber"
                                    disabled={isProgress && !!workOrderForm.initialValues?.poNumber}
                                    isInvalid={!!workOrderForm.errors.poNumber}
                                    {...workOrderForm.getFieldProps('poNumber')}
                                    value={
                                      workOrderForm?.values?.poDetails &&
                                      workOrderForm?.values?.poDetails.length
                                        ? workOrderForm?.values?.poDetails
                                            ?.map((poDetail) => {
                                              return poDetail.purchaseOrderNumber;
                                            })
                                            .join(', ')
                                        : workOrderForm?.values?.poNumber || ''
                                    }></Form.Control>
                                </Form.Group>
                                <p className="error-style">{workOrderForm.errors.poNumber}</p>
                              </Col>
                              <Col xs={3}>
                                <Form.Group>
                                  <Form.Label className="label_wodetails">
                                    Payment Terms(Days){' '}
                                    <span className="mandatory-astrik-color">*</span>
                                  </Form.Label>
                                  <Form.Control
                                    name="paymentTerm"
                                    id="paymentTerm"
                                    type="number"
                                    onKeyDown={handleKeyDown}
                                    isInvalid={!!workOrderForm.errors.paymentTerm}
                                    {...workOrderForm.getFieldProps('paymentTerm')}
                                    disabled={
                                      isProgress || (isPlannedQtyValid && isInComplete)
                                    }></Form.Control>
                                </Form.Group>
                                {workOrderForm.errors.paymentTerm && (
                                  <p className="error-style">Payment Term required.</p>
                                )}
                              </Col>
                              <Col xs={3}>
                                <Form.Group>
                                  <Form.Label className="label_wodetails">
                                    Traceability Documents{' '}
                                    <span className="mandatory-astrik-color">*</span>
                                  </Form.Label>
                                  <Form.Control
                                    as="select"
                                    className="px-3 form-control-one"
                                    name="traceabilityDocuments"
                                    id="traceabilityDocuments"
                                    onChange={dropDownHandler}
                                    disabled={inCompleteDisabled}
                                    isInvalid={!!workOrderForm.errors.traceabilityDocuments}
                                    value={workOrderForm?.values?.traceabilityDocuments}>
                                    <option key={'TRACEABILITY_DOCS_NONE'} value="">
                                      Select option
                                    </option>
                                    {TRACEABILITY_DOCS_OPTIONS.map((type) => {
                                      return (
                                        <option key={type?.id} value={type?.value}>
                                          {type?.label}
                                        </option>
                                      );
                                    })}
                                  </Form.Control>
                                </Form.Group>
                              </Col>
                              <Col xs={3}>
                                <Form.Group>
                                  <Form.Label className="label_wodetails">
                                    Fulfillment Year
                                    <span className="mandatory-astrik-color">*</span>
                                  </Form.Label>
                                  <Form.Control
                                    as="select"
                                    className="px-3 form-control-one"
                                    name="fulfillmentYear"
                                    id="fulfillmentYear"
                                    onChange={finYearHandler}
                                    isInvalid={
                                      workOrderForm?.errors?.fulfillmentYearStartDate &&
                                      (!!workOrderForm.errors.fulfillmentYearStartDate ||
                                        !!workOrderForm.errors.fulfillmentYearEndDate ||
                                        !workOrderForm.values.fulfilledYear)
                                    }
                                    value={workOrderForm.values.fulfilledYear}
                                    disabled={
                                      (isProgress && isPlanningDone) ||
                                      (isPlannedQtyValid && isInComplete)
                                    }>
                                    <option key={'FIN_YEAR_NONE'} value="">
                                      Select option
                                    </option>
                                    {account?.financialYears?.map((ele) => {
                                      return (
                                        <option key={ele?.label} value={ele?.fulfilledYear}>
                                          {ele?.label}
                                        </option>
                                      );
                                    })}
                                  </Form.Control>
                                  <Form.Control.Feedback type="invalid">
                                    {workOrderForm.errors.fulfillmentYearStartDate}
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </Col>
                              <Col xs={3}>
                                <Form.Group>
                                  <Form.Label className="label_wodetails">
                                    Fulfillment Cycle
                                  </Form.Label>
                                  <Form.Control
                                    as="select"
                                    className="px-3 form-control-one"
                                    name="fulfillmentCycle"
                                    id="fulfillmentCycle"
                                    onChange={(e) => {
                                      workOrderForm.setFieldValue(
                                        'fulfillmentCycle',
                                        e.target.value
                                      );
                                    }}
                                    isInvalid={
                                      !!workOrderForm.errors.fulfillmentCycle ||
                                      !!workOrderForm.errors.fulfillmentCycle ||
                                      !!workOrderForm.fulfillmentCycle
                                    }
                                    value={workOrderForm.values.fulfillmentCycle}
                                    disabled={(isProgress && isPlanningDone) || inCompleteDisabled}>
                                    <option key={'FULFILLMENTCYCLE'} value="">
                                      Select option
                                    </option>
                                    {FULFILLMENT_CYCLES?.map((ele) => {
                                      return (
                                        <option key={ele?.label} value={ele?.value}>
                                          {ele?.label}
                                        </option>
                                      );
                                    })}
                                  </Form.Control>
                                  <Form.Control.Feedback type="invalid">
                                    {workOrderForm.errors.fulfillmentCycle}
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </Col>
                              <Col xs={5}>
                                <Form.Group>
                                  <Form.Label
                                    className="label_wodetails"
                                    style={{ display: 'block' }}>
                                    Additional Invoicing requirements{' '}
                                    <span className="mandatory-astrik-color">*</span>
                                  </Form.Label>
                                  {[
                                    { id: '1', label: 'Yes', value: true },
                                    { id: '2', label: 'No', value: false },
                                  ].map((item, id) => (
                                    <Form.Check
                                      key={`${id}`}
                                      inline
                                      type="radio"
                                      name={`isInvoicing-${id}`}
                                      id={`inline-radio-${id + 1}`}
                                      label={item?.label}
                                      className="invoicing-radio mt-3"
                                      onChange={() => {
                                        workOrderForm.setValues({
                                          ...workOrderForm?.values,
                                          isInvoicing: item.value,
                                          invoicing: item.value,
                                          comments: null,
                                        });
                                        if (item?.value) {
                                          setCommentValidation({
                                            comments: Yup.string('Comments required')
                                              .typeError('Comments are required')
                                              .required('Comments are required'),
                                          });
                                        }
                                        if (!item?.value) {
                                          setCommentValidation({});
                                        }
                                      }}
                                      checked={workOrderForm.values?.isInvoicing === item.value}
                                      disabled={
                                        (!isNull(workOrderForm.initialValues?.isInvoicing) &&
                                          isProgress &&
                                          isFulfillmentStarted) ||
                                        inCompleteDisabled
                                      }
                                    />
                                  ))}
                                  {workOrderForm.errors?.isInvoicing && (
                                    <div className="invalid-feedback-form-check">
                                      {workOrderForm.errors?.isInvoicing}
                                    </div>
                                  )}
                                </Form.Group>
                              </Col>
                              {workOrderForm?.values?.isInvoicing && (
                                <Col xs={6}>
                                  <Form.Group>
                                    <Form.Label className="label_wodetails">
                                      Invoicing Comments{' '}
                                      <span className="mandatory-astrik-color">*</span>
                                    </Form.Label>
                                    <Form.Control
                                      name="comments"
                                      id="comments"
                                      type="comments"
                                      maxLength={600}
                                      isInvalid={!!workOrderForm.errors.comments}
                                      {...workOrderForm.getFieldProps('comments')}
                                      disabled={isProgress && isFulfillmentDone}></Form.Control>
                                  </Form.Group>
                                  {workOrderForm.errors.comments && (
                                    <p className="error-style">Comments Required.</p>
                                  )}
                                </Col>
                              )}
                            </Row>
                          </Col>
                          <Col xs={12} md={12} sm={12} lg={3.5} xl={4}>
                            <MultiUploadCard
                              documents={workOrderForm.values?.documents || []}
                              handleDocChange={handleDocumentChange}
                              deletDoc={deleteDocument}
                              canUpdate={true}
                              isEdit={true}
                              isProgress={isProgress}
                              isFulfillmentStarted={isFulfillmentStarted}
                              initialDocuments={workOrderForm?.initialValues?.documents || []}
                              status={workOrderForm?.values?.status}
                            />
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    <Col xs={12}>
                      <Row style={{ padding: '0rem 2rem' }} className={' details-filter'}>
                        <Col xs={12}>
                          <h6 className="mb-0" style={{ fontFamily: 'Inter' }}>
                            Target Allocation
                          </h6>
                        </Col>
                        <Col xs={12} className={'pt-3'}>
                          <BrandWorkOrderItemsEdit
                            isEdit={isEdit}
                            isNotMandatory={workOrderForm?.values?.draft}
                            basicDetails={workOrderForm.values}
                            updateHandler={updateDataToTable}
                            addNewRowHandler={addOrCopyRow}
                            deleteRowHandler={deleteRow}
                            copyRowHandler={addOrCopyRow}
                            deliveryExists={deliveryExists}
                            rowsToHighlight={rowsToHighlight ? rowsToHighlight : []}
                            browseNodes={browseNodes}
                            regions={regions}
                            addRow={true}
                            orderSummary={orderSummary}
                            isHUL={
                              account?.user?.profile?.id === 9053 ||
                              workOrderForm?.values?.fromCustomerId === 9053
                            }
                            isUserPRO={isUserPRO}
                            handleKeyDown={handleKeyDown}
                            isBRANDWO={true}
                            maxValueByCategory={maxValueByCategory}
                            workOrderForm={workOrderForm}
                            disabled={inCompleteDisabled}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                ) : (
                  <AccessDenied />
                )}
              </Card.Body>
            </Form>
          </Card>
        )}
      </React.Suspense>
      <CommentsModal
        modalShow={modalShow}
        handleClose={handleClose}
        workOrderForm={workOrderForm}
        mode={workOrderId ? 'edit' : 'add'}
        workOrder={originalWorkOrderData}
      />
      {goBack && (
        <PromptModal
          singleButton={false}
          show={goBack}
          title="Unsaved Changes"
          text="Are you sure you want to discard unsaved changes?"
          onDismiss={handlegoBack}
          onSuccess={goToHistory}
          onHide={handlegoBack}
          dismissBtnText="Cancel"
          successBtnText="Continue"
        />
      )}
      {saveEditedDetails && (
        <PromptInputModal
          open={saveEditedDetails}
          title="Updating Order?"
          heading="Specify Reason"
          placeholder="Write the reason here"
          buttonText="Save Changes"
          onHide={handlesaveEditedDetails}
          data={SaveEditText}
          setData={setSaveEditText}
          onSubmit={ModalsavedEditedDetails}
        />
      )}
    </div>
  );
};

export default WorkOrderCreateBrandPRO;
